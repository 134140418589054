import { Input as AntdInput, InputRef } from "antd";
import { FC, useEffect, useRef } from "react";
import cn from "classnames";

import PasswordShow from "components/Icons/PasswordShowIcon";
import PasswordHidden from "components/Icons/PasswordHiddenIcon";
import MaskedInput from "components/MaskedInput";

import { IInputProps } from "./types";

const Input: FC<IInputProps> = ({
  className,
  type = "text",
  mask,
  meta,
  value,
  error,
  ...rest
}) => {
  const ref = useRef<InputRef | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (rest.focus && ref?.current?.input) {
        ref?.current?.input.focus();
      }
    }, 1000);
  }, [ref, rest.focus]);

  const errorCheck = (meta && !!meta.error && meta?.touched) || !!error;

  if (type === "password") {
    return (
      <AntdInput.Password
        value={value}
        type={type}
        className={cn('default-input', className,  { "error-input": errorCheck })}
        iconRender={visible => (
          <div className="input-password_icon">{visible ? <PasswordShow /> : <PasswordHidden />}</div>
        )}
        role="presentation"
        {...rest}
      />
    );
  }

  if (!!mask) {
    return (
      <MaskedInput
        mask={mask}
        value={value}
        type={type}
        className={cn("default-input", className, { "error-input": errorCheck })}
        {...rest}
      />
    );
  }

  return (
    <AntdInput
      value={value}
      type={type}
      ref={ref}
      className={cn("default-input", className, { "error-input": errorCheck })}
      role="presentation"
      {...rest}
    />
  );
};


export default Input;
