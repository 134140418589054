// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setFocus = (ref: React.RefObject<HTMLElement>, tagName = 'input') => {
  const inputs = ref.current?.getElementsByTagName(tagName);
  if (inputs?.length && inputs[0]) {
    setTimeout(() => {
      (inputs[0] as HTMLElement).focus();
    }, 300);
  }
};

